<template>
<div class="my-team">
  <TopBack :title="$t('home.wdtd')"></TopBack>
  <div class="content">
    <div class="performance-quantity">
      <div class="quantity-money">{{dataInfo.score}}</div>
      <div class="quantity-text">{{$t('team.yjsl')}}(USDT)</div>
    </div>
    <div class="direct-recommended-members">{{$t('team.zjhy')}}</div>
    <div class="recommended-members-list">
      <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="$t('home.noMore')"
          :loading-text="$t('home.loading')"
          :error-text="$t('home.failed')"
          :offset="0"
          @load="onLoad"
      >
        <div class="items" v-for="(item,i) in list" :key="i">
          <div class="p">
            <div class="users" v-if="item.address">{{item.address}}</div>
            <div class="type" v-if="item.performanceAmount">{{item.performanceAmount+'(USDT)'}}</div>
          </div>
          <div class="p">
            <div class="text">{{item.maxLevel!=null?$t('team.top')+':'+getSubLevel(item.maxLevel):''}}</div>
            <div class="text">{{$t('team.achievement')}}:{{item.score}}</div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</div>
</template>

<script setup>
import TopBack from './components/TopBack'
import {ref ,onBeforeMount} from "vue";
import {getUserTeamInfo} from "../api";
import {getSubLevel} from '../utils/decimalTool'

const pageNo=ref(0)
const total=ref(-1)
const list=ref([])
const loading = ref(false);
const finished = ref(false);
const dataInfo=ref({})
const getHttp=()=>{
  getUserTeamInfo({
    "uid":sessionStorage.getItem('userId'), //用户UID
    "pageNo":pageNo.value,
    "pageSize":10,
  }).then(res=>{
    dataInfo.value=res.data
    total.value=dataInfo.value.teamInfo.page.totalNum
    let dataList = dataInfo.value.teamInfo.teamList;
    if(dataList.length>0){
      list.value.push(... dataList)
    }
  })
}
const onLoad =  () => {
  // 异步更新数据
  // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  let timeoutId = setTimeout(() => {
    // 数据全部加载完成
    if (list.value.length >= total.value &&  total.value!==-1) {
        loading.value = false;
        finished.value = true;
        clearTimeout(timeoutId);
    }else {
      loading.value = false;
      pageNo.value+=1
      getHttp()
    }
  }, 1000);
};


onBeforeMount(()=>{
  // onLoad()
})
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 0;
}
.my-team{
  box-sizing: border-box;
  height: 100vh;
  padding:0 18px 21px 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  .content{
    height: 100%;
    padding: 21px 18px;
    overflow: scroll;
    .performance-quantity{
      display: flex;
      flex-direction: column;
      align-items: center;
      .quantity-money{
        margin-top: 30px;
        font-weight: bold;
        font-size: 46px;
        color: #FFFFFF;
      }
      .quantity-text{
        margin-top: 26px;
        font-weight: bold;
        font-size: 15px;
        color: #7E8D95;
      }
    }
    .direct-recommended-members{
      text-align: left;
      margin: 50px 0 24px 0;
      font-weight: 500;
      font-size: 16px;
      color: #7E8D95;
    }
    .recommended-members-list{
      .items{
        margin-bottom: 19px;
        padding: 25px 20px;
        background: #232A2E;
        border-radius: 5px;
       .p{
         display:flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 10px;
         .users{
           font-weight: bold;
           font-size: 16px;
           color: #FFFFFF;
         }
         .type{
           font-weight: 500;
           font-size: 16px;
           color: #6FFEE8;
         }
         .text{
           font-size: 16px;
           color: #FFFFFF;
           text-align: left;
         }
       }
        .p:last-child{
          margin: 0;
        }
      }
    }
  }
}
  .top-back{
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 17px;
    color: #FFFFFF;
    position: relative;
    .top-image{
      position: absolute;
      left: 0px;
      top: 18px;
      width: 16px;
      height: 15px;
    }
  }


</style>
