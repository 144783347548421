<template>
  <div class="top-back" >
    <img class="top-image" src="@/assets/images/back.png" alt="" @click="backPageBtn">
    <div>{{props.title}}</div>
  </div>
</template>
<script setup>
import { defineProps,} from 'vue'
import {useRouter} from "vue-router";
const props = defineProps(["title"])
const router=useRouter()
const backPageBtn = () => {
  router.go(-1)
};
</script>
<style scoped lang="less">
.top-back{
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 17px;
  color: #FFFFFF;
  position: relative;
.top-image{
  position: absolute;
  left: 0px;
  top: 18px;
  width: 16px;
  height: 15px;
}
}
</style>
